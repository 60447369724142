import { Component, OnInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-dash-mix',
  templateUrl: './dash-mix.component.html',
  styleUrls: ['./dash-mix.component.css']
})
export class DashMixComponent implements OnInit {

  @Input() public comp: any;

  chart:any={};

  constructor() { }

  ngOnInit(): void {
    // // console.log('this.comp',this.comp);
    setTimeout(()=>{
      for(var x1 in this.comp.data){
        this.chart['chart-' + this.comp.id + '-' + x1] = $('.chart-' + this.comp.id + '-' + x1).peity("line",{width:$(".card-mix-chart").innerWidth(),height:$(".card-mix-chart").innerHeight(),stroke:'#FFFFFF',fill:'#FFFFFF3A'})
      }
      this.comp.update = ()=>{
        for (var x1 in this.comp.data) {
          this.chart['chart-' + this.comp.id + '-' + x1].text(this.comp.data[x1].data.join(',')).change();
        }
      }
    },100)
  }

}
