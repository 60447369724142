import { Component, OnInit, Renderer2, ElementRef, ViewChild, Input } from '@angular/core';

import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css']
})

export class SelectComponent implements OnInit {

  @Input() public element: any;
  @Input() public scopeId: any;

  @ViewChild('menu') menu: ElementRef;
  @ViewChild('list') list: ElementRef;
  @ViewChild('selectstyle') selectstyle: ElementRef;
  
  constructor(private renderer: Renderer2,private tools: ToolsService) {}

  ngOnInit(): void {
    if (!this.element.value) { this.element.value = ''; }

    this.element.optionsFilter = this.element.options || [];

    if (this.element.showOptions) this.element.showOptions = false;

    if (['select'].indexOf(this.element.elementType) >= 0) {
      this.renderer.listen('window', 'click', (e: any) => {
        if (this.element.showOptions) {
          if (![this.menu?.nativeElement, this.list?.nativeElement, this.selectstyle?.nativeElement].includes(e.target) && ![this.menu?.nativeElement?.className, this.list?.nativeElement?.className, this.selectstyle?.nativeElement?.className].includes(e.target?.className)) {
            this.element.showOptions = false;
          }
        }
      });
    }
  }

  ionViewDidEnter(){
  }

  selectItem(opt) {
    this.element.value = opt.value||opt;
    this.element.showOptions = false;
    this.funcEvent('change');
  }

  closeDelay() {
    setTimeout(() => {
      this.element.showOptions = false;
      this.funcEvent('blur');
    }, 200)
  }

  changeTypehead(value) {
    let check = (value || '').length > 0;
    if (!check) this.element.optionsFilter = this.element.options;

    this.element.showOptions = check;

    if (check) this.element.optionsFilter = this.element.options.filter(e => e.toLowerCase().includes(value.toLowerCase()));
  }

  funcEvent(type) {
    let funcEventStatus = false;

    if (type == 'focus' && this.element.focus) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.focus, this.element, this.scopeId);
    }

    if (type == 'change' && this.element.change) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.change, this.element, this.scopeId);
    }

    if (type == 'blur' && this.element.blur) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.blur, this.element, this.scopeId);
    }

    if (!funcEventStatus && type != 'focus') {
      this.tools.setItem(this.element.pageType, (this.scopeId || 'item'), this.element.modelBind||this.element.model, this.element.value);
    }
  }

}
