import { Component, Input, OnInit } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() public element: any;
  @Input() public scopeId: any;

  constructor(
    private tools:ToolsService
  ) { }

  ngOnInit(): void {
    if(!this.element.id){this.element.id=this.element.model+'-'+new Date().getTime()}
  }

  clickEvent(){
    this.element.value = !this.element.value;
    this.funcEvent('click');
  }

  funcEvent(type){
    let funcEventStatus = false
    if(type=='click'&&this.element.click){
      funcEventStatus=true;
      this.tools.runFunc(this.element.click,this.element,this.scopeId);
    }
    if(!funcEventStatus&&type=='click'&&this.element.model){
      this.tools.setItem(this.element.pageType,(this.scopeId||'item'),this.element.model,this.element.value);
    }
  }

}
