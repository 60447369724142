<!-- <div class="collapse-base">
  <div class="collapse-card">
    <div class="collapse-header">
      <div class="title">
        {{comp.name}}
      </div>
      <div class="open">
        >
      </div>
    </div>
    <div class="collapse-body">
      data
    </div>
  </div>
</div> -->

<div class="wrap-collabsible">
  <input id="collapsible2" class="toggle" type="checkbox">
  <label for="collapsible2" class="lbl-toggle">
    {{comp.name}}
  </label>
  <div class="collapsible-content">
    <div class="content-inner">
      <p>{{comp.text}}</p>
    </div>
  </div>
</div>