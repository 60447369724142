<div class="card-mix" [ngStyle]="{'background':(comp.color||'#18647E')}">
  <div class="card-mix-title">
    {{comp.name}}
  </div>
  <div class="card-mix-grid">
    <div *ngFor="let item of comp.data; let i = index" class="card-mix-group">
      <div class="card-mix-number">{{item.value}}</div>
      <div class="card-mix-text">
        {{item.name}}
        <div *ngIf="item.tooltipblue || item.tooltipwhite" class="tooltip">
          <span
          class="tooltiptext"
          [ngClass]="item.tooltipwhite?'tooltiptextwhite':item.tooltipblue?'tooltiptextblue':''"
          >{{item.tooltipwhite?item.tooltipwhite:item.tooltipblue}}</span>
        </div>
      </div>
      <div class="card-mix-chart"><span class="card-mix-chart-line" [ngClass]="'chart-'+comp.id+'-'+i">{{item.data.join(',')}}</span></div>
    </div>
  </div>
  <div *ngIf="comp.value!=undefined" class="card-mix-details card-mix-value">{{comp.value}}</div>
  <div *ngIf="comp.legend" class="card-mix-legend">{{comp.legend}} </div>
  <div *ngIf="comp.click" class="card-mix-details clicavel">{{comp.clickText}} <i class="fa fa-angle-right"></i></div>
</div>