<div [ngClass]="!comp.nocard?'item-grid':'no-grid'" class="item-ranking">
  <div class="item-title" *ngIf="comp.title">{{comp.title}}</div>
  <div class="item-subtitle" *ngIf="comp.subtitle">{{comp.subtitle}}</div>
  <div class="item" *ngFor="let item of comp.data; index as i">
    <div class="first-item" *ngIf="i==0" [ngStyle]="{'background-color': comp.color?comp.color:'rgb(24, 100, 126)'}">
      <div class="item-image">
        <img [src]="comp.data[0].img" onError="this.src='https://static.applayos.com:3000/files/produtos/default.jpg'"
          alt="">
      </div>
      <div class="content-item">
        <div class="top">Top 1</div>
        <div class="column" style="font-weight: 800; font-size: 14px;">{{comp.data[0].nome}}</div>
        <div class="column" style="font-weight: 800; font-size: 12px;">Qtd Impressões: {{comp.data[0].cupons}}</div>
        <div class="column">Volume: {{comp.data[0].qtd}}</div>
        <div class="column">Venda Total: {{comp.data[0].valorText}}</div>
      </div>
    </div>

    <div class="others-itens" *ngIf="i!=0">
      <div class="top-others">Top {{i+1}}</div>
      <div class="item-image">
        <img [src]="item.img" onError="this.src='https://static.applayos.com:3000/files/produtos/default.jpg'" alt="">
      </div>
      <div class="content-item-others">
        <div class="column" style="font-weight: 800;">{{item.nome}}</div>
        <div class="column" style="font-weight: 800; font-size: 12px;">Qtd Impressões: {{item.cupons}}</div>
        <div class="column">Volume: {{item.qtd}}</div>
        <div class="column">Venda Total: {{item.valorText}}</div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="teste">
  <div class="item-title" *ngIf="comp.title">{{comp.title}}</div>
  <div class="item-subtitle">{{comp.data.subtitle}}</div>
  <div class="item-image"><img [src]="comp.data.img" onError="this.src='https://static.applayos.com:3000/files/produtos/default.jpg'" alt=""></div>
  <div class="item-description">{{comp.data.imgTitle}}</div>
  <div class="item-subdescription">{{comp.data.imgSubtitle}}</div>
  <div class="item-info-grid">
    <div *ngFor="let item of comp.data.details" class="item-info">
      <div class="item-info-text">{{item.value}} {{item.title}}</div>
      <div *ngIf="s" [ngClass]="'item-info-number color-'+(item.type=='credit'?'success':'danger')"><i [ngClass]="'fa fa-arrow-'+(item.type=='credit'?'up':'down')"></i> {{item.typeValue}}</div>
    </div>
  </div>
  <div class="item-footer">
    <div class="item-footer-title">{{comp.data.bottomTitle}}</div>
    <div class="item-footer-details" *ngIf="comp.data.click">ver lista  <i class="fa fa-angle-right"></i></div>
    <div class="item-footer-number">{{comp.data.bottomValue}}</div>
  </div>
</div> -->