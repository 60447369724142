import { Component, OnInit, Input } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-dash-number',
  templateUrl: './dash-number.component.html',
  styleUrls: ['./dash-number.component.css']
})
export class DashNumberComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {
    // setTimeout(()=>{
    //   $(".donut-chart-peity").peity("donut",{ "width":74,"height":74,"fill": this.comp.data.map(e=>e.color), "innerRadius": 20, "radius": 32 })
    // },100)
  }

  genMap(data){
    return data.map(e=>e.value).join(',')
  }

}
