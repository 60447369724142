import { Component, Input, OnInit } from '@angular/core';
import { ModsService } from '../../../services/mods.service';

declare var $: any;
@Component({
  selector: 'app-dash-item-ranking',
  templateUrl: './dash-item-ranking.component.html',
  styleUrls: ['./dash-item-ranking.component.css']
})
export class DashItemRankingComponent implements OnInit {


  @Input() public comp: any;

  constructor(
    private mods: ModsService
  ) { }

  ngOnInit(): void {
  }

  click(item, systemName) {
    // console.log("🚀 ~ systemName", systemName)
    // console.log("🚀 ~ item", item)
    // console.log("🚀 ~ comp", comp)
    this.mods.getMod(item, systemName);
  }
}
