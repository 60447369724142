import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {
    
  }

}
