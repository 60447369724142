import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-line',
  templateUrl: './dash-line.component.html',
  styleUrls: ['./dash-line.component.css']
})
export class DashLineComponent implements OnInit {

  @Input() public comp: any;

  public chartOptionsBase: any = {
    series: [
      {
        name: "base",
        data: [0]
      }
    ],
    chart: {
      height: 350,
      type: "line",
      zoom: {
        enabled: false
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "straight"
    },
    title: {
      text: "Product Trends by Month",
      align: "left"
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep"
      ]
    }
  };
  public chartOptions: any;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = Object.assign(this.chartOptionsBase,this.comp.data);
  }
}
