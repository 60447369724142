<div class="layout-base" *ngIf="conected">
  <div class="layout-header"><app-header></app-header></div>
  <div class="layout-menu"><app-menu></app-menu></div>
  <div class="layout-workspace"><app-workspace></app-workspace></div>

</div>
<div class="layout-alert" *ngIf="alert.status"><app-alert [alert]="alert"></app-alert></div>
<div class="loading" [ngClass]="{'show-loading':loading}" *ngIf="loading">
  <div class="loading-title">{{loadingMsg || 'Carregando...'}}</div>
  <img src="assets/loading-mod.gif" alt="">
</div>
