<div [ngClass]="!comp.nocard?'donut-grid':'no-grid'" [ngStyle]="{'cursor':(comp.click?'pointer':'')}" (click)="click(comp.modulePath, comp.system)">
  <div class="donut-title">
    {{comp.name}}
    <div *ngIf="comp.tooltipblue || comp.tooltipwhite" class="tooltip">
      <span
      class="tooltiptext"
      [ngClass]="comp.tooltipwhite?'tooltiptextwhite':comp.tooltipblue?'tooltiptextblue':''"
      >{{comp.tooltipwhite?comp.tooltipwhite:comp.tooltipblue}}</span>
    </div>
  </div>
  <div class="donut-subtitle">{{comp.subtitle}}</div>
  <div class="donut-chart" [attr.id]="comp.id">
    <span class="donut-chart-peity">{{genMap(comp.data)}}</span>
  </div>
  <div class="donut-legend">
    <div *ngFor="let item of comp.data" class="donut-legend-item">
      <div class="donut-legend-item-point" [ngStyle]="{'background':(item.color)}"></div>
      <div class="donut-legend-item-text">
        {{item.name}} {{comp.type=='currency'?'R$ ':''}}{{item.value}}{{comp.type=='perc'?'%':!comp.type?'%':''}}
        <!-- {{comp.perc==false?'':'%'}} -->
      </div>
      <div class="donut-legend-subitem-text">
        {{item.subValue}}
      </div>
    </div>
    <!-- <div class="donut-legend-item">
      <div class="donut-legend-item-point"></div>
      <div class="donut-legend-item-text">
        Não fidelizada 60,9%
      </div>
    </div> -->
  </div>
</div>