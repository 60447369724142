import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})

export class InputComponent implements OnInit {

  @Input() public element: any;
  @Input() public scopeId: any;
  @Input() public inline: any = false;

  repeatRateTimer: any;
  mask: any = {}

  valueDatepicker: any;

  constructor(
    private tools: ToolsService
  ) { }

  ngOnInit(): void {
    this.mask = {
      'numero': '0*',
      'float': 'separator.2',
      'telefone': '(00) 0000-0000||(00) 0 0000-0000',
      'hora': '00:00',
      'data': '00/00/0000',
      'cpf': '000.000.000-00',
      'cnpj': '00.000.000/0000-00',
      'email': 'A*@A*.SSS.SS',
      'rg': '00.000.000-0',
      'cep': '00000-000',
      // 'password': '•*'
    }

    if (this.element.elementType == 'datepicker') {
      if(!this.element.value || this.element.value == ''){
        delete this.element.valueDatepicker;
        delete this.element.value;
      }
    }

  }

  ngAfterViewInit() { }

  keyupEvent() {
    clearTimeout(this.repeatRateTimer);
    this.repeatRateTimer = setTimeout(() => {
      if (this.element.elementType == 'search' && this.element.table) {
        this.tools.search(this.element.pageType, this.element.table, this.element.value);
      }
      this.tratData('change');
    }, this.element.changeTime || 700);
  }

  tratData(type) {
    if (!this.element.valueDatepicker && this.element.elementType == 'datepicker') return

    if (this.element.elementType == 'datepicker' && this.element.valueDatepicker) {
      this.element.value = this.element.valueDatepicker.split('-').reverse().join('/');
    }

    if (this.element.mask == 'numero') {
      this.element.value = parseFloat(this.element.value);
    }

    this.funcEvent(type);
  }

  funcEvent(type) {
    let funcEventStatus = false;

    if (type == 'focus' && this.element.focus) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.focus, this.element, this.scopeId);
    }

    if (type == 'change' && this.element.change) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.change, this.element, (this.scopeId || 'item'));
    }

    if (type == 'blur' && this.element.blur) {
      funcEventStatus = true;
      this.tools.runFunc(this.element.blur, this.element, this.scopeId);
    }
    
    if (!funcEventStatus && type == 'blur') {
      this.tools.setItem(this.element.pageType, (this.scopeId || 'item'), this.element.model, this.element.value);
    }
  }

}
