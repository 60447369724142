<div [ngClass]="(!comp.nocard?'card-number-grid':'no-grid')" [ngStyle]="{'background':'#FFFFFF'}">
  <div *ngIf="comp.numberType=='imagem'" style="display: flex; justify-content: center; align-items: center; height: 100%;">
    <img style="width: 90%;" src="{{comp.imagem}}">
  </div>

  <div *ngIf="comp.numberType!='imagem'" style="display: flex; flex-direction: column; gap: 16px;">
    <div class="card-number-title">{{comp.name}}</div>

    <div class="number-mix" *ngIf="comp.numberType=='mix'">
      <div class="card-number-text-item" *ngFor="let item of comp.data; let i = index" [ngStyle]="{'font-size':item.fontSize ? item.fontSize : i == 0 ? '1.725rem': '1.125rem','line-height':item.fontSize ? item.fontSize : i == 0 ? '1.725rem': '1.125rem', 'font-weight': i == 0 ? '600' : '', 'color':item.color?item.color:''}">
        <div *ngIf="item.subtitle">{{item.subtitle}}</div>
        <div>{{item.value}}</div>
      </div>
    </div>

    <div *ngIf="comp.stars && comp.stars.length > 0" class="stars">
      <div *ngFor="let item of comp.stars" class="stars-line">
        <i class="fa fa-star" [ngStyle]="{'color':item.stars>=1?'#FFD800':'#E6E6E6'}"></i>
        <i class="fa fa-star" [ngStyle]="{'color':item.stars>=2?'#FFD800':'#E6E6E6'}"></i>
        <i class="fa fa-star" [ngStyle]="{'color':item.stars>=3?'#FFD800':'#E6E6E6'}"></i>
        <div class="stars-number">
          {{item.value}}
        </div>
        <div class="stars-number-perc">
          {{(item.value/comp.data.value)*100}}%
        </div>
      </div>
    </div>

    <div *ngIf="comp.numberType!='mix'" class="card-number-text" [ngStyle]="{'color':comp.color?'comp.color':''}">{{comp.data.value}}</div>
    <div *ngIf="comp.numberType!='mix'" class="card-number-subtitle" [ngStyle]="{'color':comp.color?'comp.color':'#FFFFFF'}">{{comp.data.subtitle}}</div>

    <div *ngIf="comp.click" class="card-number-details">{{comp.clickText}} <i class="fa fa-angle-right"></i></div>
  </div>

</div>