import { Component, Input, OnInit } from '@angular/core';
import 'bootstrap';
import * as $ from 'jquery';
import { ModsService } from 'src/app/services/mods.service';

declare var $: any;

@Component({
  selector: 'app-dash-donut',
  templateUrl: './dash-donut.component.html',
  styleUrls: ['./dash-donut.component.css']
})
export class DashDonutComponent implements OnInit {

  @Input() public comp: any;
  @Input() public element: any;
  @Input() public scopeId: any;

  constructor(private mods: ModsService) { }

  ngOnInit(): void {
    $('[data-toggle="tooltip"]').tooltip()

    // console.log('donut',this.comp)
    setTimeout(() => {
      $("#" + this.comp.id + " .donut-chart-peity").peity("donut", { "width": 74, "height": 74, "fill": this.comp.data.map(e => e.color), "innerRadius": 20, "radius": 32 })
      // setTimeout(()=>{
      //   this.comp.data.forEach((e,i)=>{
      //     // console.log('e',i,$(".peity path")[i])
      //     $('.peity').children('svg').children('path').each(function () {
      //         alert( $(this).attr('fill') );
      //     });
      //     // $(".peity path")[i].attr('title',e.name);
      //     // $('.peity path')[i].tooltip({
      //     //   title: '<h4>'+e.name+'</h4>',
      //     //   html: true,
      //     //   placement: 'bottom',
      //     //   container: 'body'
      //     // });
      //   })
      // },5000)
    }, 100)
  }

  genMap(data) {
    return data.map(e => e.value).join(',')
  }


  click(item,systemName){
    console.log("🚀 ~ systemName", systemName)
    console.log("🚀 ~ item", item)
    // console.log("🚀 ~ comp", comp)
    this.mods.getMod(item,systemName);
  }

}
