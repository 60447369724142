import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-map',
  templateUrl: './dash-map.component.html',
  styleUrls: ['./dash-map.component.css']
})
export class DashMapComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {
  }

}
