import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
  selector: 'app-dash-layout',
  templateUrl: './dash-layout.component.html',
  styleUrls: ['./dash-layout.component.css']
})
export class DashLayoutComponent implements OnInit {

  @Input() public comp: any;
  qtdLivre:any = 0;
  qtdOcupado:any = 0;
  qtdReservado:any = 0;
  qtdTotal:any = 0
  
  constructor(private tools:ToolsService) { }
  
  ngOnInit(): void {
    for(let linha of this.comp.data){
      for(let box of linha.data){
        this.qtdTotal++
        if(box.status == 'success'){
          this.qtdLivre++
        }
        if(box.status == 'danger'){
          this.qtdOcupado++
        }
        if(box.status == 'primary'){
          this.qtdReservado++
        }
      }
    }

  }

  click(box){
    this.tools.cmdFunc('layer', this.comp.click, box);
  }

}
