<div [ngClass]="!comp.nocard?'item-grid':'no-grid'" [ngStyle]="{cursor: comp.data.click ? 'pointer' : 'default'}" (click)="click(comp.modulePath, comp.system)">
  <div class="item-content">
    <div *ngIf="!!comp.title" class="item-title">{{comp.title}}</div>
    <div *ngIf="!!comp.data.subtitle" class="item-subtitle">{{comp.data.subtitle}}</div>
    <div class="item-image"><img [src]="comp.data.img" onError="this.src='https://static.applayos.com:3000/files/produtos/default.jpg'" alt=""></div>
    <div *ngIf="!!comp.data.imgTitle" class="item-description">{{comp.data.imgTitle}}</div>
    <div *ngIf="!!comp.data.imgSubtitle" class="item-subdescription">{{comp.data.imgSubtitle}}</div>
    <div *ngIf="comp.data.details && comp.data.details.length > 0" class="item-info-grid">
      <div *ngFor="let item of comp.data.details" class="item-info">
        <div class="item-info-text">{{item.value}} {{item.title}}</div>
        <div *ngIf="s" [ngClass]="'item-info-number color-'+(item.type=='credit'?'success':'danger')"><i [ngClass]="'fa fa-arrow-'+(item.type=='credit'?'up':'down')"></i> {{item.typeValue}}</div>
      </div>
    </div>
  </div>
  <div *ngIf="!!comp.data.bottomTitle || !!comp.data.bottomValue" class="item-footer">
    <div *ngIf="!!comp.data.bottomTitle" class="item-footer-title">{{comp.data.bottomTitle}}</div>
    <div class="item-footer-details" *ngIf="comp.data.click">ver lista  <i class="fa fa-angle-right"></i></div>
    <div *ngIf="!!comp.data.bottomValue" class="item-footer-number">{{comp.data.bottomValue}}</div>
  </div>
</div>
