import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashColumnComponent } from './dash-column/dash-column.component';
import { DashMixComponent } from './dash-mix/dash-mix.component';
import { DashDonutComponent } from './dash-donut/dash-donut.component';
import { DashItemComponent } from './dash-item/dash-item.component';
import { DashNumberComponent } from './dash-number/dash-number.component';
import { DashNewComponent } from './dash-new/dash-new.component';
import { DashBarComponent } from './dash-bar/dash-bar.component';
import { DashLineComponent } from './dash-line/dash-line.component';
import { DashMultiComponent } from './dash-multi/dash-multi.component';
import { DashMapComponent } from './dash-map/dash-map.component';
import { DashBaseComponent } from './dash-base/dash-base.component';
import { DashFunnelComponent } from './dash-funnel/dash-funnel.component';
import { BrowserModule } from '@angular/platform-browser';
import { DashTemplateAppComponent } from './dash-template-app/dash-template-app.component';
import { DashHighComponent } from './dash-high/dash-high.component';
import { DashLayoutComponent } from './dash-layout/dash-layout.component';
import { DashItemRankingComponent } from './dash-item-ranking/dash-item-ranking.component'

@NgModule({
  declarations: [DashColumnComponent, DashMixComponent, DashDonutComponent, DashItemComponent, DashNumberComponent, DashNewComponent, DashBarComponent, DashLineComponent, DashMultiComponent, DashMapComponent, DashBaseComponent, DashFunnelComponent, DashTemplateAppComponent, DashHighComponent, DashLayoutComponent, DashItemRankingComponent],
  imports: [
    BrowserModule,
    CommonModule,
  ],
  exports: [DashColumnComponent, DashMixComponent, DashDonutComponent, DashItemComponent, DashItemRankingComponent, DashNumberComponent, DashNewComponent, DashBarComponent, DashLineComponent, DashMultiComponent, DashMapComponent, DashBaseComponent, DashFunnelComponent, DashTemplateAppComponent, DashHighComponent, DashLayoutComponent],
  entryComponents: [DashColumnComponent, DashMixComponent, DashDonutComponent, DashItemComponent, DashItemRankingComponent, DashNumberComponent, DashNewComponent, DashBarComponent, DashLineComponent, DashMultiComponent, DashMapComponent, DashBaseComponent, DashFunnelComponent, DashTemplateAppComponent, DashHighComponent, DashLayoutComponent]
})
export class DashModule { }
