import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-bar',
  templateUrl: './dash-bar.component.html',
  styleUrls: ['./dash-bar.component.css']
})
export class DashBarComponent implements OnInit {

  @Input() public comp: any;

  public chartOptionsBase: any = {
    series: [
      {
        name: "Net Profit",
        data: [0]
      }
    ],
    colors:['#09242c', '#09242c', '#18647E','#00C292'],
    chart: {
      type: "bar",
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded"
      }
    },
    dataLabels: {
      enabled: false,
      style: {
        colors:['#09242c', '#09242c', '#18647E','#00C292']
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"]
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct"
      ]
    },
    yaxis: {
      title: {
        text: " ()"
      }
    },
    fill: {
      colors:['#09242c', '#09242c', '#18647E','#00C292'],
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: function(val) {
          return " " + val + " ";
        }
      }
    }
  };
  public chartOptions: any;

  constructor() { }

  ngOnInit(): void {
    this.chartOptions = Object.assign(this.chartOptionsBase,this.comp.data);
  }

}
