<div class="base-item">
  <div class="base-label">{{element.name}}</div>

  <div *ngIf="['select'].indexOf(element.elementType)>=0">
    <div class="select-custom">
      <div class="select-style" (click)="element.showOptions=!element.showOptions" #selectstyle>
        {{element.bind||element.value}}
      </div>
      <i [ngStyle]="{'display':(!element.showOptions?'block':'none')}" class="fa fa-arrow-down" (click)="element.showOptions=true"></i>
      <i [ngStyle]="{'display':(element.showOptions?'block':'none')}" class="fa fa-arrow-up" (click)="element.showOptions=false"></i>
      <ul class="options" [ngStyle]="{'display':(element.showOptions?'block':'none')}" #menu>
        <li (click)="selectItem(opt)" *ngFor="let opt of element.optionsObj||element.options" #list>{{opt.view||opt}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="['typehead'].indexOf(element.elementType)>=0">
    <div class="typehead-custom">
      <input type="text" placeholder="" name="" (blur)="closeDelay()" [(ngModel)]="element.value" (keyup)="changeTypehead(element.value)" (focus)="funcEvent('focus')">
      <i [ngStyle]="{'display':(element.showOptions?'block':'none')}" (click)="element.showOptions=!element.showOptions" class="fa fa-arrow-up"></i>
      <span *ngIf="element.optionsFilter.length == 0" class="typehead-notfound">{{element.subtitle || 'Nenhum item encontrado'}}</span>
      
      <ul class="options" [ngStyle]="{'display':(element.showOptions?'block':'none')}" [style.visibility]="element.optionsFilter.length == 0? 'hidden': 'visible'">
        <li (click)="selectItem(opt)" *ngFor="let opt of element.optionsFilter">{{opt}}</li>
      </ul>
    </div>
  </div>

  <div *ngIf="['radio'].indexOf(element.elementType)>=0">
    <select class="" name="">
      <option value="A">B</option>
    </select>
  </div>
</div>
