<div class="base-item">
  <div class="base-label">{{element.name}}</div>
  <div *ngIf="['multiselect'].indexOf(element.elementType)>=0">
    <input class="input-style" type="text" [(ngModel)]="element.value" (blur)="funcEvent('blur')" (keyup)="keyupEvent()" (focus)="funcEvent('focus')">
  </div>
  <div *ngIf="['checkbox'].indexOf(element.elementType)>=0">
    <div class="input-search">
      <i class="fa fa-search"></i>
      <input type="text" placeholder="Buscar por" name="" value="">
    </div>
  </div>
</div>
