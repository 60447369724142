<div class="base-item">
  <div *ngIf="['input','search','datepicker','textarea'].indexOf(element.elementType)>=0">
    <app-input [element]="element" [scopeId]="scopeId" [inline]="inline"></app-input>
  </div>
  <div *ngIf="['select','typehead','radio'].indexOf(element.elementType)>=0">
    <app-select [element]="element" [scopeId]="scopeId"></app-select>
  </div>
  <div *ngIf="['multiselect','checkbox'].indexOf(element.elementType)>=0">
    <app-multi-select [element]="element" [scopeId]="scopeId"></app-multi-select>
  </div>
  <div *ngIf="['button','switchbutton','switch'].indexOf(element.elementType)>=0">
    <app-button [element]="element" [scopeId]="scopeId"></app-button>
  </div>
  <div *ngIf="['buttongroup','dropdown'].indexOf(element.elementType)>=0">
    <app-button-group [element]="element" [scopeId]="scopeId"></app-button-group>
  </div>
  <div *ngIf="['file'].indexOf(element.elementType)>=0">
    <app-file [element]="element" [scopeId]="scopeId"></app-file>
  </div>
</div>
