import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';
import { SyncService } from './sync.service';
import { ToolsService } from './tools.service';
import { LangService } from './lang.service';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  user: any = {};
  notificationsStatus: boolean = false;
  notifications: any = [];
  menu: any = [];
  mods: any = [];
  userStatus: boolean = false;
  moduloUsuario: any = '';
  hash: any = '';

  constructor(
    private api: ApiService,
    private sync: SyncService,
    private tools: ToolsService,
    private lang: LangService,
    private router: Router
  ) { }

  getUser() {
    return this.user;
  }

  relogin(username,password){
    var hash = CryptoJS.SHA256(password).toString();
    return username==this.user.username && hash==this.hash
  }

  getUserInfo() {
    return {
      nome: this.user.nome,
      username: this.user.username,
      userId: this.user._id,
      useremail: this.user.email
    };
  }

  getStatus() {
    return this.userStatus;
  }

  getMenu(callback) {
    let menu = (this.user.modsV3 || []);
    let usermodslocals = menu
    let dataFinalModulos = { base: 'sysModsV3', filter: { click: { $in: usermodslocals }, ativo: true, naoExibir: { $ne: true } } }// base: 'sysModsv3'

    // Get mods
    this.api.portal('getData'.toLowerCase(), dataFinalModulos, (result: any) => {

      let basemods = result.data;

      let modUser = basemods.find(e => e.local.toLowerCase().includes('usuariosv3'));
      if (modUser && modUser.local) {
        this.moduloUsuario = modUser.local;
      }

      let usersystemsnome = basemods.map(e => e.system)
      let dataFinalSystems = { base: 'sysCatV3', filter: { nome: { $in: usersystemsnome }, ativo: true, naoExibir: { $ne: true } } }// base: 'sysCatV3'

      // Get cats
      this.api.portal('getData'.toLowerCase(), dataFinalSystems, (resultsystems: any) => {
        if (result.data.length < 1) {
          return callback({ data: [] })
        }

        let basesystems = resultsystems.data

        basesystems.forEach(e => {
          e.showList = true
          let list = basemods.filter(f => usermodslocals.indexOf(f.local) >= 0 && f.system == e.nome)
          e.list = list.sort((a, b) => (+a.order > +b.order) ? 1 : ((+a.order < +b.order) ? -1 : 0))
          return e
        });

        let newMenu = basesystems.sort((a, b) => (+a.order > +b.order) ? 1 : ((+a.order < +b.order) ? -1 : 0))

        this.mods = basemods;
        this.menu = newMenu;
        callback({ data: newMenu, modulos: basemods });
      });
    });
  }

  getModsMenu() {
    return { mods: this.mods, menu: this.menu }
  }

  async loadNotifications(callback) {
    this.tools.getNotificationsCad('cadNotificacoes', { username: this.user.username, readed: { $ne: true } }, (result) => {
      this.notifications = result
      this.notificationsStatus = true
      return callback(result);
    })
  }

  async getNotifications(callback) {
    if (this.notificationsStatus) {
      callback([...this.notifications]);
    } else {
      let res = await this.loadNotifications(callback);
      this.notifications = res
    }
  }

  setNotifications(data) {
    this.notifications = data
    let dataFinalUpdate = { base: 'cadNotificacoes', list: [] }

    //GET NOTIFICAÇÕES
    this.api.portal('getdata', { base: 'cadNotificacoes', filter: { username:this.user.username } }, (result) => {
      if (result.data && result.data != '') {
        result.data.forEach(e => e.readed = true);
        dataFinalUpdate.list = result.data
      } else {
        dataFinalUpdate.list = data
      }

      //SAVE NOTIFICAÇÕES
      this.api.portal('saveData'.toLowerCase(), dataFinalUpdate, (resultSave: any) => {
      });
    });

  }

  insertNotifications(data,callback) {
    // if (data.para == 'Todos' || data.para == this.user.username) {
    data.username = this.user.username;
    let dataFinalUpdate = { base: 'cadNotificacoes', list: [data] }
    this.api.portal('saveData'.toLowerCase(), dataFinalUpdate, (result: any) => {
      data._id = result.data?.ids[0]?._id?result.data?.ids[0]?._id:result.data.regs.insertedIds[0]._id
      this.notifications.unshift(data);
      callback(this.notifications);
    });
  }

  recuperarSenha(emailSenha: any, callback) {
    let url = 'https://' + window.location.hostname;
    let cliente = this.api.getClient();

    this.api.eauth('esqueciminhasenhav1', { email: emailSenha, url, cliente }, (result) => {
      callback(result)
    });
  }

  salvarSenha(senhaNova: any, senhacode: any, callback) {
    var hash = CryptoJS.SHA256(senhaNova).toString();

    this.api.eauth('esqueciminhasenhav2', { newhash: hash, codigo: senhacode }, (result) => {
      callback(result)
    });
  }

  sendmfa(username: any, callback) {
    this.api.eauth('sendmfa', { email: username }, (result) => {
      callback(result)
    });
  }

  mfaCheck(codigo, callback) {
    this.api.eauth('checkmfa', { email: this.user.username, codigo }, (result) => {
      if (result.status) {
        this.userStatus = true;
      }
      callback(result.status)
    });
  }

  logout() {
    this.user = {};
    this.userStatus = false;
    this.api.setSession(this.user);
    this.sync.stopSync();
    this.tools.clearTools();
    this.router.navigate(['/login'], { fragment: "" });
  }

  login(username, password, callback) {
    var hash = CryptoJS.SHA256(password).toString();

    this.api.eauth('login', { username: username, hash: hash }, (result) => {
      if (result.status) {
        this.hash = hash;
        this.user = result.data;
        this.userStatus = true;

        if (this.user.mfa) this.userStatus = false;

        this.api.setSession(this.user);

        this.tools.setUserInfo({
          username: this.user.username,
          name: this.user.name,
          ...this.user
        });

        this.sync.setSession(this.user.username, this.user.session, this.user.useremail, this.user.perfil);

        // this.lang.setLang(this.user.lang || this.lang.lang);
        callback(null, this.user);
      } else {
        callback(result.msg, null);
      }
    })
  }
}
