<div class="base-item" [ngStyle]="{'position':element.class?'relative':'none'}" >
  <div class="base-label" [ngStyle]="{'position':element.class?'absolute':'none'}">{{element.name}}</div>

  <div *ngIf="['button'].indexOf(element.elementType)>=0">
    <div (click)="clickEvent()" [ngClass]="'button button-'+(element.color)">
      <i *ngIf="element.icon" [ngClass]="'fa fa-'+(element.icon)" style="padding-right: 15px;"></i>
      {{element.text}}
    </div>
  </div>

  <div *ngIf="['switch'].indexOf(element.elementType)>=0">
    <div class="switch-apy" [ngClass]="[element.class?element.class:'',element.class?element.value==true?'class1-on':'class1-off':'']" (click)="clickEvent()">
      <div class="switch-apy-selector" [ngClass]="[element.value?'switch-apy-true':'switch-apy-false', element.class?'class1-selector':'']"></div>
      <div class="switch-apy-off" [class.class1-off]="element.class?'class1-off':''" [ngClass]="[element.class?element.value==true?'class1-on-bgcolor':'class1-off-bgcolor':'',element.value ?'':'selecionado']">{{element.text.no.toUpperCase()}}</div>
      <div class="switch-apy-on" [class.class1-on]="element.class?'class1-on':''" [ngClass]="[element.class?element.value==true?'class1-on-bgcolor':'class1-off-bgcolor':'',element.value ?'selecionado':'']">{{element.text.yes.toUpperCase()}}</div>
    </div>
  </div>

  <div *ngIf="['switchbutton'].indexOf(element.elementType)>=0">
    <div (click)="clickEvent()" [ngClass]="'button button-'+(!element.text.yes?element.text:(element.value?element.text.yes:element.text.no))">
      {{!element.text.yes?element.text:(element.value?element.text.yes:element.text.no)}}
    </div>
  </div>
  
</div>
