import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  @Input() public comp: any;

  constructor(
    private tools:ToolsService
  ){}

  ngOnInit(): void {}

  checkView(view,pageType,scope){
    return this.tools.checkView(view,pageType,scope);
  }

}
