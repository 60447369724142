import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.css']
})
export class MultiSelectComponent implements OnInit {

  @Input() public element: any;
  @Input() public scopeId: any;

  constructor(
    private tools:ToolsService
  ) { }

  ngOnInit(): void {
  }

  funcEvent(type){
    let funcEventStatus = false
    if(type=='click'&&this.element.click){
      funcEventStatus=true;
      this.tools.runFunc(this.element.focus,this.element,this.scopeId);
    }
  }

}
