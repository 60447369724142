<div
  class="form-layer"
  [ngClass]="{'bordered':comp.border, 'form-layer-right': comp.align=='right', 'form-layer-center': comp.align=='center'}"
  [class.unbordershadow]="comp.border=='unbordershadow'"
  [style.height]="comp.height || 'auto'"
>
  
  <div *ngIf="comp.showname" class="nameform">{{comp.name}}</div>
  
  <div *ngFor="let element of comp.elements" class="form-component" [ngStyle]="{'flex':'0 0 '+(100/12*(element.size||12))+'%', 'display':element.show?'block':'none' }">
    <app-base class="component-item" [element]="element" [scopeId]="comp.scope" [inline]="comp.inline"></app-base>
  </div>
</div>
