<div [ngClass]="!comp.nocard?'card-bar-grid':'no-grid'">
  <div class="card-bar-title">{{comp.name}}</div>
  <div class="card-bar-chart">
    <!-- <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [plotOptions]="chartOptions.plotOptions"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [xaxis]="chartOptions.xaxis"
      [yaxis]="chartOptions.yaxis"
      [fill]="chartOptions.fill"
      [tooltip]="chartOptions.tooltip"
    >
    </apx-chart> -->
  </div>
</div>
