import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.css']
})
export class TextComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {
    // this.comp.data.forEach(e=>e.value=e.model);
  }

}
