<div class="base-item" [ngClass]="element.position?'position-base-label':''" [class.inline]="inline">
    <div class="base-label" [ngClass]="element.position?'position-base':''" [class.titleBold]="element.class && element.class == 'titleBold'">
      <i *ngIf="element.icon" [ngClass]="'fa fa-'+(element.icon)" style="padding-right: 15px;"></i>
      {{element.name}}
    </div>
    
    <div *ngIf="['input'].indexOf(element.elementType)>=0" style="flex:1">
      <input *ngIf="element.mask=='moeda'"
        currencyMask
        [ngStyle]="{'height':element.position?'100%':'none'}"
        [class.saldo]="element.saldo=='saldo'"
        [class.unbackground]="element.class=='unbackground'"
        class="input-base {{element?.color ? 'bg-'+element.color: 'input-style' }}"
        [ngClass]="{'isblock':element.block&&!element.color, 'position-input':element.position=='side'?'position-input':''}"
        [attr.type]="element.mask=='password'?'password':'text'"
        [(ngModel)]="element.value"
        (blur)="funcEvent('blur')"
        (keyup)="keyupEvent()"
        (focus)="funcEvent('focus')"
        (ngModelChange)="keyupEvent()"
        [attr.disabled]="element.block"
        [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', align: 'left' }"
        [attr.dir]="element.dir"
      >
      <input *ngIf="element.mask=='float'"
        [ngStyle]="{'height':element.position?'100%':'none'}"
        [class.saldo]="element.saldo=='saldo'"
        [class.unbackground]="element.class=='unbackground'"
        class="input-base {{element?.color ? 'bg-'+element.color: 'input-style' }}"
        [ngClass]="{ 'isblock':element.block&&!element.color, 'position-input':element.position=='side'?'position-input':''}"
        [attr.type]="element.mask=='password'?'password':'text'"
        [mask]="mask[element.mask]"
        [(ngModel)]="element.block?(element.bind||element.value):element.value"
        (blur)="funcEvent('blur')"
        (keyup)="keyupEvent()"
        (focus)="funcEvent('focus')"
        (ngModelChange)="keyupEvent()"
        [attr.disabled]="element.block"
        [attr.dir]="element.dir"
        thousandSeparator="." 
        decimalMarker=","
      >

      <input *ngIf="element.mask!='moeda'&&element.mask!='float'"
        [ngStyle]="{'height':element.position?'100%':'none'}"
        [class.saldo]="element.saldo=='saldo'"
        [class.unbackground]="element.class=='unbackground'"
        class="input-base {{element?.color ? 'bg-'+element.color: 'input-style' }}"
        [ngClass]="{ 'isblock':element.block&&!element.color, 'position-input':element.position=='side'?'position-input':''}"
        [attr.type]="element.mask=='password'?'password':'text'"
        [mask]="mask[element.mask]"
        [(ngModel)]="element.block?(element.bind||element.value):element.value"
        (blur)="funcEvent('blur')"
        (keyup)="keyupEvent()"
        (focus)="funcEvent('focus')"
        (ngModelChange)="keyupEvent()"
        [attr.disabled]="element.block"
        [attr.dir]="element.dir"
      >
    </div>

    <div *ngIf="['textarea'].indexOf(element.elementType)>=0">
      <textarea class="textarea-style" [ngClass]="{'isblock':element.block}" type="text" [attr.rows]="element.rows||4" [(ngModel)]="element.value" (blur)="funcEvent('blur')" (keyup)="keyupEvent()" (focus)="funcEvent('focus')"></textarea>
    </div>
    
    <div *ngIf="['search'].indexOf(element.elementType)>=0">
      <div class="input-search">
        <i class="fa fa-search"></i>
        <input type="text" placeholder="Buscar por" [(ngModel)]="element.value" [mask]="mask[element.mask]" (blur)="funcEvent('blur')" (keyup)="keyupEvent()" (focus)="funcEvent('focus')">
      </div>
    </div>
    
    <div *ngIf="['datepicker'].indexOf(element.elementType)>=0">
      <div class="input-datepicker">
        <input type="date" [(ngModel)]="element.valueDatepicker" (blur)="tratData('blur')" (focus)="funcEvent('focus')" (ngModelChange)="keyupEvent()" [ngClass]="{'isblock':element.block}">
      </div>
    </div>
  </div>
