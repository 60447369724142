
import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SyncService } from '../../services/sync.service';
import { UserService } from '../../services/user.service';
import { ApiService } from '../../services/api.service';
import { LangService } from 'src/app/services/lang.service';

declare var $;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
  // version: any = '0.19.8';
  version: any = '0.20.0';
  versionDefault: any = '1.0.0';

  clientes: any = [];

  subscription: Subscription;

  showPass: boolean = false;
  showBox: any = 'login';
  showLang: any = false;

  client: string = 'multi';
  multi: boolean = true;
  clientSelected: any = '';
  clienteObj: any = {}
  email: any = '';
  username: any = '';
  password: any = '';
  remember: boolean = false;
  emailSenha: any = '';
  password1: any = '';
  password2: any = '';
  passAlert: boolean = true;
  senhacode: any = '';
  mfaCodigo: any = '';

  isSophia: Boolean = false;
  prelogo: Boolean = false;

  status: boolean = false;
  statusText: any = 'Selecione um sistema';

  loadingMsg: any = 'Carregando..'
  loading: any = false;

  languages: any = [
    {
      "title": "pt-BR",
      "lang": "pt",
      "msg": {
        "escolha": "Escolha uma Linguagem",
        "caminho": "Você está aqui",
        "sair": "Sair",
        "notificacoes": "Notificações",
        "semNotificacoes": "Sem Notificações",
        "marcarLidas": "Marcar todas como Lidas",
        "minhaConta": "Minha Conta",
        "buscarPor": "Buscar Por",
        "removerItem": "Tem certeza que deseja remover ?",
        "login": {
          "msg_conectado": "Conectado",
          "title_sistema": "Sistema",
          "title_login": "Login",
          "title_senha": "Senha",
          "title_lembrar_senha": "Lembrar senha",
          "btn_entrar": "ENTRAR",
          "btn_esqueci_senha": "Esqueci minha senha"
        }
      },
      "status": true
    },
    {
      "title": "en-US",
      "lang": "en",
      "msg": {
        "escolha": "Choose a Language",
        "caminho": "Location",
        "sair": "Logout",
        "notificacoes": "Notifications",
        "semNotificacoes": "No results",
        "marcarLidas": "Mark all as read",
        "minhaConta": "My Account",
        "buscarPor": "Search by",
        "removerItem": "Are you sure to delete?",
        "login": {
          "msg_conectado": "Conected",
          "title_sistema": "System",
          "title_login": "Login",
          "title_senha": "Password",
          "title_lembrar_senha": "Remember password",
          "btn_entrar": "LOGIN",
          "btn_esqueci_senha": "Forgot password?"
        }
      },
      "status": true
    }
  ];

  language: any = this.languages[0];

  constructor(
    private user: UserService,
    private api: ApiService,
    private renderer: Renderer2,
    private sync: SyncService,
    private router: Router,
    private route: ActivatedRoute,
    private lang: LangService
  ) { }

  async ngOnInit(): Promise<void> {
    
    
    this.prelogo = window.location.hostname.includes('blstech.com.br');
    this.loading = true;
    this.clientes = await this.api.loadClients();
    // console.log('init')
    // console.log("🚀 -> this.clientes:", this.clientes);

    

    // Jornada esqueci minha senha
    this.senhacode = this.route.snapshot.paramMap.get('senhacode');
    console.log("🚀 ~ LoginComponent ~ ngOnInit ~ senhacode:", this.senhacode)
    if (this.senhacode && this.senhacode != '') {
      // console.log('Senhacode: ', this.senhacode);

      let clientPass = this.route.snapshot.paramMap.get('client');
      console.log("🚀 ~ LoginComponent ~ ngOnInit ~ clientPass:", clientPass)

      this.setClient(clientPass);
      this.showBox = 'newpass';
      this.loading = false;
      return;
    }

    // Usuario salvo no localStorage
    let remember = JSON.parse(localStorage.getItem('_LOGIN'));
    if (remember) {
      // console.log('Remember: ', remember);

      this.username = remember.username;
      this.password = remember.password;
      this.remember = true;
    }

    // Processo padrão
    const checkDefault = this.clientes.find(e => e.default)
    await this.setClient(checkDefault ? checkDefault.produto : this.clientes[0].produto);

    this.multi = this.clientes.length > 1;

    this.subscription = this.sync.listenSync.subscribe(data => {
      if (data.type == 'status') {
        this.status = data.status;
        this.statusText = data.statusText;
      }
    });

    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.showLang) this.showLang = !($(e.target).parents('.header-lang').length == 0);
    });

    this.loading = false;
  }

  ngOnDestroy() {
    if (!this.senhacode || this.senhacode == '') {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  async setClient(clientSelected) {
    this.clientSelected = clientSelected;
    this.status = false;
    this.statusText = 'Conectando...';


    this.clienteObj = await this.api.setClient(clientSelected);

    let client = this.api.getClient()
    if(client!='BLSTECH'){
      this.versionDefault = this.version;
    }
    console.log('client',client);

    if (this.clienteObj) this.sync.connect();
    else this.statusText = 'Selecione um sistema';
  }

  mfaCheck(remeber?) {
    this.user.mfaCheck(this.mfaCodigo, (acesso) => {
      if (acesso) {
        if (this.remember) {
          localStorage.setItem('_MFA_' + this.api.getClient(), this.mfaCodigo)
        }
        this.router.navigate(['/'], { fragment: "" });
      } else {
        if (!remeber) {
          alert('Código de verificação inválido');
        } else {
          //enviarNovoMFA
          this.showBox = 'mfa';
          this.mfaCodigo = '';
          this.user.sendmfa(this.username, (result) => {
            alert(result.msg)
          })
        }
      }
    });
  }

  backLogin() {
    this.showBox = 'login';
    this.router.navigate(['/login'], { fragment: "" });
  }

  login() {
    if (this.remember) {
      let { username, password, clientSelected } = this;
      localStorage.setItem('_LOGIN', JSON.stringify({ username, password, clientSelected }))
    } else {
      localStorage.removeItem('_LOGIN');
    }

    // if (this.client == 'multi') {
    //   this.setClient(this.clientSelected);
    // }

    this.user.login(this.username, this.password, (err, userLogin) => {
      if (err) {
        alert(err);
      } else {
        if (this.remember) {
          let mfaCodigo = localStorage.getItem('_MFA_' + this.api.getClient());
          if (mfaCodigo && mfaCodigo != '') {
            this.mfaCodigo = mfaCodigo + '';
            this.mfaCheck(true);
            return
          }
        }
        if (userLogin.mfa) {
          this.showBox = 'mfa';
          //enviarNovoMFA
          this.user.sendmfa(this.username, (result) => {
            alert(result.msg)
          })
          return
        }

        this.router.navigate(['/'], { fragment: "" });
      }
    });
  }

  sendMail() {
    if (this.emailSenha != '' && this.emailSenha.split('@').length >= 2) {
      this.user.recuperarSenha(this.emailSenha, (result) => {
        alert(result.msg)
      })
    }
  }

  sendNewPassword() {
    //verificação campos senha
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/.test(this.password1)) {
      alert('Digite sua nova senha. Ela deve conter no mínimo\n8 caracteres sendo 1 letra maiúscula, 1 letra minúscula, 1 número e um símbolo.')
      return
    }
    if (this.password1 == this.password2) {

      this.user.salvarSenha(this.password1, this.senhacode, (result) => {
        if (result.code != 200) {
          alert(result.msg)
        } else {
          this.showBox = 'confirm';
        }

      })
    } else {
      alert('As senhas devem ser iguais!')
    }
  }

  checkPass() {
    if (this.password1 != '' && this.password2 != '' && this.password2 != this.password1) {
      return true
    } else {
      return false
    }
  }

  togglePassword() {
    this.showPass = !this.showPass;
  }

  forgetPassword() {
    if (this.client == 'multi') {
      this.setClient(this.clientSelected);
    }
    this.showBox = 'recover';
  }

  clickLang(item) {
    setTimeout(() => {
      this.language = item;
      this.lang.lang = item;
    }, 100);
  }
}
