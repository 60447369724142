import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  @Input() public comp: any;

  constructor( private tools:ToolsService) { }

  ngOnInit(): void {}

  selectNav(opt){
    console.log(opt);
    
    this.comp.value = opt.value || opt;
    this.funcEvent('click');
  }

  funcEvent(type){
    let funcEventStatus = false
    if(type=='click'&&this.comp.click){
      funcEventStatus=true;
      this.tools.runFunc(this.comp.click,this.comp,(this.comp.scope||'item'));
    }
    if(!funcEventStatus&&type=='click'&&this.comp.model){
      this.tools.setItem(this.comp.pageType,(this.comp.scope||'item'),this.comp.model,this.comp.value);
    }
  }

}
