<div class="login-background {{clientSelected}}">

  <div class="loading" [ngClass]="{'show-loading':loading}" *ngIf="loading">
    <div class="loading-title">{{loadingMsg || 'Carregando...'}}</div>
    <img src="assets/loading-mod.gif" alt="">
  </div>

  <div class="header-lang" (click)="showLang = !showLang" *ngIf="clientSelected.toLowerCase().includes('sophia')">
    <div class="lang-item-avatar-texto">
      <div class="lang-item-avatar">
        <img [src]="'../../../assets/images/bandeiras/'+language.lang+'.png'" class="lang-item-avatar" alt="">
      </div>
      <span class="item">{{language.title.toUpperCase()}}</span>
      <div>
        <img src="assets/svg/icon-arrow-down.svg" alt="">
      </div>
    </div>
    <div class="header-notif-drop-lang" [hidden]="!showLang">
      <div class="menu-pointer-lang"></div>
      <div class="header-conteudo-lang">
        <div *ngFor="let item of languages" class="header-notif-item-lang" (click)="clickLang(item)">
          <div class="notif-item-content">
            <div class="lang-item-avatar">
              <img [src]="'../../../assets/images/bandeiras/'+item.lang+'.png'" class="lang-item-avatar" alt="">
            </div>
            <div class="notif-item-text">
              <div class="notif-item-title-lang">
                {{item.title.toUpperCase()}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div *ngIf="showBox=='login'" class="login-container login-box">
    <div class="login-imagem">
      <img src="{{clienteObj?.tema?.logo}}" loading="lazy" onError="this.src='assets/images/logo_black.png'" alt="Logo"
        style="max-width: 300px;">
    </div>
    <div *ngIf="!!clientSelected"
      [ngClass]="'login-status '+(status?'': statusText == 'Conectando...'? 'login-warning':  'login-danger')">
      {{statusText}}</div>
    <div class="version">v{{version}}</div>
    <div class="login-form-item" *ngIf="multi">
      <div class="login-input-text">{{language?.msg?.login?.title_sistema || 'Sistema'}}</div>
      <div class="login-input-item">
        <select type="text" [(ngModel)]="clientSelected" name="" value="" (ngModelChange)="setClient($event)">
          <option *ngFor="let cliente of clientes" [attr.value]="cliente.value">{{cliente.produto}}</option>
        </select>
      </div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">{{language?.msg?.login?.title_login || 'Login-1'}}</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="username" name="" value="" (keyup.enter)="login()">
      </div>
    </div>
    <div class="login-form-item">
      <div class="login-input-text">{{language?.msg?.login?.title_senha || 'Senha-1'}}</div>
      <div class="login-input-item"><input [(ngModel)]="password" [attr.type]="showPass?'text':'password'" name=""
          value="" (keyup.enter)="login()"><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')"
          (click)="togglePassword()"></i></div>
    </div>
    <div class="login-form-item">
      <div class="login-input-item"><input [(ngModel)]="remember" type="checkbox"
          style="margin-right: 5px;">{{language?.msg?.login?.title_lembrar_senha || 'Lembrar senha-1'}}</div>
    </div>
    <div class="login-form-item" (click)="login()">
      <div class="login-button">{{language?.msg?.login?.btn_entrar || 'ENTRAR-1'}}</div>
    </div>
    <div class="login-form-item login-lostpass" (click)="forgetPassword()">{{language?.msg?.login?.btn_esqueci_senha ||
      'Esqueci minha senha-1'}}</div>
  </div>

  <div *ngIf="showBox=='recover'" class="login-container login-recover">
    <div class="login-form-item login-info login-info-first">
      Digite seu e-mail abaixo e clique em Enviar. Nós lhe enviaremos uma email com link para uma página onde você
      poderá criar uma nova senha.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">E-mail</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="emailSenha" name="" value="email"></div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="sendMail()">
        ENVIAR
      </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="backLogin()">
      voltar ao login
    </div>
  </div>

  <div *ngIf="showBox=='mfa'" class="login-container login-recover">
    <div class="login-form-item login-info login-info-first">
      Verificação em 2 etapas foi enviado um código no seu email {{this.username}}.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Código de Autorização</div>
      <div class="login-input-item"><input type="text" [(ngModel)]="mfaCodigo" name="" value="email"></div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="mfaCheck()">
        Autorizar
      </div>
    </div>
    <div class="login-form-item login-lostpass" (click)="backLogin()">
      voltar ao login
    </div>
  </div>

  <div *ngIf="showBox=='newpass'" class="login-container login-newpass">
    <div class="login-form-item login-title">
      nova senha
    </div>
    <div class="login-form-item login-info">
      Digite sua nova senha. Ela deve conter no mínimo<br>8 caracteres sendo 1 letra maiúscula, 1 letra minúscula, 1
      número e um símbolo.
    </div>
    <div class="login-form-item">
      <div class="login-input-text">Nova senha</div>
      <div class="login-input-item"><input [(ngModel)]="password1" [attr.type]="showPass?'text':'password'" name=""
          value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div class="login-input-item login-t10"><input [(ngModel)]="password2" [attr.type]="showPass?'text':'password'"
          name="" value=""><i [ngClass]="'far fa-'+(showPass?'eye':'eye-slash')" (click)="togglePassword()"></i></div>
      <div *ngIf="checkPass()" class="login-input-text login-t10">As senhas não conferem</div>
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="sendNewPassword()">
        Enviar
      </div>
    </div>
  </div>

  <div *ngIf="showBox=='confirm'" class="login-container login-confirm">
    <div class="login-form-item login-icon">
      <i class="fa fa-check"></i>
    </div>
    <div class="login-form-item login-title2">
      NOVA senha criada COM SUCESSO!
    </div>
    <div class="login-form-item">
      <div class="login-button" (click)="backLogin()">
        FAZER LOGIN
      </div>
    </div>
  </div>
</div>