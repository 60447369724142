import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-dash-multi',
  templateUrl: './dash-multi.component.html',
  styleUrls: ['./dash-multi.component.css']
})
export class DashMultiComponent implements OnInit {

  @Input() public comp: any;

  constructor() {

  }

  ngOnInit(): void {
    this.comp.charts.forEach(e=>e.nocard=true)
  }

}
