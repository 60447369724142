import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {

  @Input() public comp: any;

  constructor(
    private tools:ToolsService
  ) { }

  ngOnInit(): void {
  }

  toMap(options){
    return options.map(e=>e.value);
  }

  selectStep(opt){
    this.comp.valueOld = this.comp.value+'';
    this.comp.value = opt.value || opt;
    this.funcEvent('click');
  }

  funcEvent(type){
    let funcEventStatus = false
    if(type=='click'&&this.comp.click){
      funcEventStatus=true;
      this.tools.runFunc(this.comp.click,this.comp,(this.comp.scope||'item'),{old:this.comp.valueOld});
    }
    if(!funcEventStatus&&type=='click'&&this.comp.model){
      this.tools.setItem(this.comp.pageType,(this.comp.scope||'item'),this.comp.model,this.comp.value);
    }
  }

}
