<!-- <div class="step-box">
  <div class="step-item" [ngClass]="{'current':step==comp.value,'next':i>comp.options.indexOf(comp.value)}" *ngFor="let step of comp.options;let i=index" (click)="selectStep(step)">
    <div class="step-number">{{i+1}}</div>
    <div class="step-text">{{step}}</div>
    <div class="step-right" *ngIf="i+1<comp.options.length"><i class="fa fa-angle-right"></i></div>
  </div>
</div> -->
<div class="step-box">
  <div class="step-item" [ngClass]="{'current':(step.value||step)==comp.value,'next':i>(comp.optionsObj?toMap(comp.optionsObj):comp.options).indexOf(comp.value)}" *ngFor="let step of (comp.optionsObj||comp.options);let i=index" (click)="selectStep(step)">
    <div class="step-number-apy">{{i+1}}</div>
    <div class="step-text-apy">{{step.view||step}}</div>
    <div class="step-right-apy" *ngIf="i+1<comp.options.length"><i class="fa fa-angle-right"></i></div>
  </div>
</div>
