<div class="content-ia">

  <!-- {{comp | json}} -->

  <div class="content-logo-ia">
    <span class="icon-edit">
    </span>

    <div class="content-logo">
      <img class="image" src="https://static.applayos.com/files/crm/8060096f16f2ac07f4ca0ca204d6dc1f-Logo_Ia.png" alt="">
    </div>

    <span class="icon-edit" (click)="click(comp, comp.openModal)">
      <i class="fa-regular fa-gear"></i>
    </span>
  </div>

  <div class="divisor"></div>

  <h1 class="title">Oportunidades de ativações</h1>

  <div class="content-opportunities">

    <div class="content-calendar" *ngIf="calendar">
      <span style="margin-right: '32px'" (click)="click(calendar, calendar.buttonPrevious)">
        <i class="btn fa-solid fa-caret-left"></i>
      </span>
      <div class="content-calendar-titles">
        <span class="data">{{calendar.date}}</span>
        <span class="dia">{{calendar.day}}</span>
      </div>
      <span style="margin-left: '32px'" (click)="click(calendar, calendar.buttonNext)">
        <i class="btn fa-solid fa-caret-right"></i>
      </span>
    </div>

    <div class="content-cards" *ngIf="cards">
      <div *ngFor="let card of cards.cards" class="card" (click)="click(card, card.click)">
        <div class="header-card">{{card.header}}</div>
        <div class="content-card">{{card.content}}</div>
        <div class="footer-card">{{card.footer}}</div>
      </div>
    </div>

    <div class="content-carousel-items" *ngIf="carousel">
      <div class="content-header-carousel-items">
        <div class="title-items">Oportunidade de identificação</div>
        <span class="button-download-items" (click)="click(carousel, carousel.downloadItens)">
          <i class="fa-solid fa-download"></i>
        </span>
      </div>
      <div class="content-carousel">
        <span style="margin-left: '76px'" class="content-btn" (click)="click(carousel, carousel.buttonPrevious)">
          <i class="btn fa-solid fa-caret-left"></i>
        </span>
        <div class="content-items">
          <div class="content-item" *ngFor="let item of carousel.items; index as i">
            <div class="content-image"><img class="image"
                [src]="item.image?item.image:'https://static.applayos.com:3000/files/produtos/default.jpg'" alt="">
            </div>
            <div class="description">{{item.name}}</div>
            <div class="description">{{item.sku}}</div>
          </div>
          <!-- 
          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">2º- Azeite Extravirgem Argentino Nucete Vidro 500ml </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">3º- Arroz Camil Agulhinha 2kg </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">4º- Atum Gomes Da Costa Sólido Natural 120g </div>
          </div>

          <div class="content-item">
            <div class="image"><img src="" alt=""></div>
            <div class="description">5º- Bombom Nestlé Especialidades - 251g </div>
          </div> -->
        </div>
        <span style="margin-right: '76px'" class="content-btn" (click)="click(carousel, carousel.buttonNext)">
          <i class="btn fa-solid fa-caret-right"></i>
        </span>
      </div>
    </div>

  </div>


</div>