<div class="dashboard-grid" [ngClass]="{'template-app' : comp.charts[0].chartType=='template-app'}" [ngStyle]="{'min-height': comp.height || '300px'}">
  <div *ngFor="let chart of comp.charts" class="dashboard-component" [ngClass]="{'dashboard-npd':chart.chartType=='column'}" [ngStyle]="{'flex':(100/12*chart.size)+'%'}">
    <div *ngIf="chart.chartType=='number'" class="component-item"><app-dash-number [comp]="chart"></app-dash-number></div>
    <div *ngIf="chart.chartType=='template-app'" class="component-item"><app-dash-template-app [comp]="chart"></app-dash-template-app></div>
    <div *ngIf="chart.chartType=='item'" class="component-item"><app-dash-item [comp]="chart"></app-dash-item></div>
    <div *ngIf="chart.chartType=='item-ranking'" class="component-item"><app-dash-item-ranking [comp]="chart"></app-dash-item-ranking></div>
    <div *ngIf="chart.chartType=='column'" class="component-item"><app-dash-column [comp]="chart"></app-dash-column></div>
    <div *ngIf="chart.chartType=='donut'" class="component-item"><app-dash-donut [comp]="chart"></app-dash-donut></div>
    <div *ngIf="chart.chartType=='mix'" class="component-item"><app-dash-mix [comp]="chart"></app-dash-mix></div>
    <div *ngIf="chart.chartType=='new'" class="component-item"><app-dash-new [comp]="chart"></app-dash-new></div>
    <div *ngIf="chart.chartType=='bar'" class="component-item"><app-dash-bar [comp]="chart"></app-dash-bar></div>
    <div *ngIf="chart.chartType=='line'" class="component-item"><app-dash-line [comp]="chart"></app-dash-line></div>
    <div *ngIf="chart.chartType=='multi'" class="component-item"><app-dash-multi [comp]="chart"></app-dash-multi></div>
    <div *ngIf="chart.chartType=='map'" class="component-item"><app-dash-map [comp]="chart"></app-dash-map></div>
    <div *ngIf="chart.chartType=='funnel'" class="component-item"><app-dash-funnel [comp]="chart"></app-dash-funnel></div>
    <div *ngIf="chart.chartType=='highcharts'" class="component-item"><app-dash-high [comp]="chart"></app-dash-high></div>
    <div *ngIf="chart.chartType=='layout'" class="component-item"><app-dash-layout [comp]="chart"></app-dash-layout></div>
    <div *ngIf="['column','mix','donut','item', 'item-ranking','number','new','bar','line','multi','map','funnel','template-app','highcharts','layout'].indexOf(chart.chartType)<0" class="component-item">chartType inválido - {{chart.chartType}} - {{chart.name}}</div>
  </div>
</div>