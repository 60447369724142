<div *ngIf="element.typeFile=='image'">
  <div class="input-applay">
    <input id="fileImage" type="file" name="fileImage" class="inputfile-applay" (change)="changeImage($event.target)" [ngClass]="{'uploaded-image-applay':fileToUpload}">
    <label for="fileImage" [ngStyle]="{'height': (fileToUpload || element.value) && element.height ? element.height :  element.height || '232px' }">
      <div class="preview-image" *ngIf="!fileToUpload && !element.value">
        <svg class="drop" xmlns="http://www.w3.org/2000/svg" width="68.472" height="76.08" viewBox="0 0 68.472 76.08">
          <g id="noun_drag_and_drop_4076502" data-name="noun_drag and drop_4076502" transform="translate(-6 -4)">
            <g id="Grupo_11184" data-name="Grupo 11184" transform="translate(6 4)">
              <path id="Caminho_7081" data-name="Caminho 7081" d="M9.8,41.242l11.208-6.4a3.827,3.827,0,0,0,1.863-3.928l-1.316-7.89H44.04V47.746h3.8V23.02a3.809,3.809,0,0,0-3.8-3.8h-25.8L6,39.61V76.276a3.809,3.809,0,0,0,3.8,3.8h19.02v-3.8H9.8Zm3.474-6.366,5.01-8.35.835,5.01ZM36.432,17.314h-3.8V11.608h3.8Zm1.9-9.51H32.628V4h5.706Zm9.51,0H42.138V4h5.706Zm3.8-3.8h5.706V7.8H51.648Zm9.51,0h5.706V7.8H61.158ZM74.472,4V9.706h-3.8V4Zm-3.8,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm-1.9,9.51h5.706v3.8H68.766Zm-9.51,0h5.706v3.8H59.256ZM57,61.054l-1.9.006H57v5.706c0,6.64-2.342,10.433-3.8,12.151V80.08h-3.8V77.39l.557-.557c.762-.762,3.247-3.739,3.247-10.067V61.06H53.2l-.006-1.9c0-.509-.195-.685-.366-.782a1.241,1.241,0,0,0-1.166.011.752.752,0,0,0-.364.708v.053h-.006l.006,1.9-3.8.011-.006-1.9c0-.016.005-.03.006-.046l-.006-2.844a.82.82,0,0,0-.927-.919h0a.861.861,0,0,0-.971.914l.006,4.79h-.006v0h-1.9l-1.9,0v0h-.006V54.438c0-.505-.191-.68-.359-.776a1.245,1.245,0,0,0-1.168.011.766.766,0,0,0-.375.763l.006,6.622-3.8,0-.006-4.72c0-.507-.193-.682-.36-.778a1.252,1.252,0,0,0-1.166.011.766.766,0,0,0-.375.763l.006,4.72h-.006v3.275l-1.3.433c-1.813.606-2.017,1.473-2.086,1.757-.338,1.439.726,4.4,4.731,8.409l.557.557V80.08h-3.8V77.052c-4.081-4.261-5.92-8.292-5.186-11.4a5.9,5.9,0,0,1,3.284-3.954V59.158h0l0-2.816a4.551,4.551,0,0,1,2.273-4.06,5.042,5.042,0,0,1,4.134-.354,4.472,4.472,0,0,1,1.574-1.548,5.051,5.051,0,0,1,4.974-.013,4.4,4.4,0,0,1,1.521,1.489,5.04,5.04,0,0,1,1.706-.306h.007A4.6,4.6,0,0,1,50.988,54.6a5.03,5.03,0,0,1,3.729.477,4.564,4.564,0,0,1,2.277,4.071Z" transform="translate(-6 -4)" fill="rgba(0,194,146,0.5)"/>
            </g>
          </g>
        </svg>
        <div class="text">#Arraste e solte aqui</div>
      </div>
      <img *ngIf="fileToUpload || element.value" [src]="element.value || fileToUpload.url" />
    </label>
  </div>
</div>

<div *ngIf="element.typeFile=='pdf'">
  <div class="input-applay">
    <input id="filePdf" type="file" name="filePdf" class="inputfile-applay" (change)="changePdf($event.target)" [ngClass]="{'uploaded-image-applay':fileToUploadPdf}">
    <label for="filePdf">
      <svg class="drop" xmlns="http://www.w3.org/2000/svg" width="68.472" height="76.08" viewBox="0 0 68.472 76.08">
        <g id="noun_drag_and_drop_4076502" data-name="noun_drag and drop_4076502" transform="translate(-6 -4)">
          <g id="Grupo_11184" data-name="Grupo 11184" transform="translate(6 4)">
            <path id="Caminho_7081" data-name="Caminho 7081" d="M9.8,41.242l11.208-6.4a3.827,3.827,0,0,0,1.863-3.928l-1.316-7.89H44.04V47.746h3.8V23.02a3.809,3.809,0,0,0-3.8-3.8h-25.8L6,39.61V76.276a3.809,3.809,0,0,0,3.8,3.8h19.02v-3.8H9.8Zm3.474-6.366,5.01-8.35.835,5.01ZM36.432,17.314h-3.8V11.608h3.8Zm1.9-9.51H32.628V4h5.706Zm9.51,0H42.138V4h5.706Zm3.8-3.8h5.706V7.8H51.648Zm9.51,0h5.706V7.8H61.158ZM74.472,4V9.706h-3.8V4Zm-3.8,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm0,9.51h3.8v5.706h-3.8Zm-1.9,9.51h5.706v3.8H68.766Zm-9.51,0h5.706v3.8H59.256ZM57,61.054l-1.9.006H57v5.706c0,6.64-2.342,10.433-3.8,12.151V80.08h-3.8V77.39l.557-.557c.762-.762,3.247-3.739,3.247-10.067V61.06H53.2l-.006-1.9c0-.509-.195-.685-.366-.782a1.241,1.241,0,0,0-1.166.011.752.752,0,0,0-.364.708v.053h-.006l.006,1.9-3.8.011-.006-1.9c0-.016.005-.03.006-.046l-.006-2.844a.82.82,0,0,0-.927-.919h0a.861.861,0,0,0-.971.914l.006,4.79h-.006v0h-1.9l-1.9,0v0h-.006V54.438c0-.505-.191-.68-.359-.776a1.245,1.245,0,0,0-1.168.011.766.766,0,0,0-.375.763l.006,6.622-3.8,0-.006-4.72c0-.507-.193-.682-.36-.778a1.252,1.252,0,0,0-1.166.011.766.766,0,0,0-.375.763l.006,4.72h-.006v3.275l-1.3.433c-1.813.606-2.017,1.473-2.086,1.757-.338,1.439.726,4.4,4.731,8.409l.557.557V80.08h-3.8V77.052c-4.081-4.261-5.92-8.292-5.186-11.4a5.9,5.9,0,0,1,3.284-3.954V59.158h0l0-2.816a4.551,4.551,0,0,1,2.273-4.06,5.042,5.042,0,0,1,4.134-.354,4.472,4.472,0,0,1,1.574-1.548,5.051,5.051,0,0,1,4.974-.013,4.4,4.4,0,0,1,1.521,1.489,5.04,5.04,0,0,1,1.706-.306h.007A4.6,4.6,0,0,1,50.988,54.6a5.03,5.03,0,0,1,3.729.477,4.564,4.564,0,0,1,2.277,4.071Z" transform="translate(-6 -4)" fill="rgba(0,194,146,0.5)"/>
          </g>
        </g>
      </svg>
      <div class="text drop">Arraste e solte aqui</div>
    </label>
  </div>
</div>

<div *ngIf="!['image','image1','image2','pdf'].includes(element.typeFile)">
  <div class="input-applay">
    <input id="fileInput" type="file" name="fileInput" class="inputfile-applay" multiple (change)="ufile($event.target)" [ngClass]="{'uploaded-image-applay':fileToUpload}">
    <label for="fileInput">
      <div class="center-content">
        <img src="assets/svg/icon-drag-drop.svg">
        <div class="text">#Arraste e solte aqui</div>
      </div>
    </label>
  </div>
</div>