import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dash-template-app',
  templateUrl: './dash-template-app.component.html',
  styleUrls: ['./dash-template-app.component.css']
})
export class DashTemplateAppComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {  
    // console.log('length', this.comp.data.length)  
  }

}
