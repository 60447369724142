import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../services/tools.service';

declare var document: any

@Component({
  selector: 'app-dashia',
  templateUrl: './dashia.component.html',
  styleUrls: ['./dashia.component.css']
})
export class DashiaComponent implements OnInit {

  @Input() public comp: any;
  @Input() public element: any;
  @Input() public scopeId: any;

  calendar: any;
  cards: any;
  carousel: any;

  constructor(
    private tools: ToolsService
  ) {
  }

  ngOnInit(): void {
    console.log("🚀 ~ this.comp:", this.comp)
    this.calendar = this.comp.elements.find(e => e.type == 'carouselDate')
    this.cards = this.comp.elements.find(e => e.type == 'cards')
    this.carousel = this.comp.elements.find(e => e.type == 'carouselItems')

    let html = document.querySelector('html')
    if (html) {
      html.classList.remove('ajuste-zoom')
    }
  }

  ngOnDestroy() {
    console.log("🚀 ~ ngOnDestroy, dashia:")
    let html = document.querySelector('html')
    if (html) {
      html.classList.add('ajuste-zoom')
    }
  }

  async click(element: object, func: string) {
    this.tools.cmdFunc('layer', func, element);
  }

}
