import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { InputComponent } from './input/input.component';
import { SelectComponent } from './select/select.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { ButtonComponent } from './button/button.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { FileComponent } from './file/file.component';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";


@NgModule({
  declarations: [BaseComponent, InputComponent, SelectComponent, MultiSelectComponent, ButtonComponent, ButtonGroupComponent, FileComponent],
  imports: [
    FormsModule,
    CommonModule,
    NgxMaskModule.forRoot(),
    NgxCurrencyModule
  ],
  exports: [BaseComponent, InputComponent, SelectComponent, MultiSelectComponent, ButtonComponent, ButtonGroupComponent, FileComponent],
  entryComponents: [BaseComponent, InputComponent, SelectComponent, MultiSelectComponent, ButtonComponent, ButtonGroupComponent, FileComponent]
})
export class ElementsModule { }
