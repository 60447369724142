import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { WorkspaceComponent } from './workspace/workspace.component';
import { LoginComponent } from './login/login.component';
import { FormsModule,ReactiveFormsModule }   from '@angular/forms';
import { HomeComponent } from './home/home.component';
import { ComponentsModule } from '../components/components.module';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  declarations: [HeaderComponent, MenuComponent, WorkspaceComponent, LoginComponent, HomeComponent, AlertComponent],
  imports: [
    FormsModule,
    ComponentsModule,
    CommonModule
  ],
  exports: [HeaderComponent, MenuComponent, WorkspaceComponent, LoginComponent, HomeComponent, AlertComponent],
  entryComponents: [HeaderComponent, MenuComponent, WorkspaceComponent, LoginComponent, HomeComponent, AlertComponent]
})
export class LayoutModule { }
