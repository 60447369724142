<div class="full-click" (click)="cancel('outside')"></div>

<div *ngIf="alert.alertType=='alert'" class="alert-container alert-confirm">
  <div [ngClass]="'alert-form-item alert-icon alert-'+(alert.color)">
    <i [ngClass]="'fa fa-'+(alert.icon)"></i>
  </div>
  <div class="alert-form-item alert-title2">
    {{alert.msg}}
  </div>
  <div class="alert-form-item">
    <div class="alert-button" (click)="cancel('button')">
      OK !
    </div>
  </div>
</div>

<div *ngIf="alert.alertType=='confirm'" class="alert-container alert-confirm">
  <div [ngClass]="'alert-form-item alert-icon alert-'+(alert.color)">
    <i [ngClass]="'fa fa-'+(alert.icon)"></i>
  </div>
  <div class="alert-form-item alert-title2">
    {{alert.msg}}
  </div>
  <div class="alert-form-item">
    <!-- <div class="alert-buttonTwo" (click)="cancel('button')"> -->
    <div [ngClass]="alert.buttonTwoCustom?'alert-buttonTwo':'alert-button'" (click)="cancel('button')">
      Não
    </div>
    <div class="alert-button" (click)="confirm('buttonOne')">
      Sim
    </div>
  </div>
</div>

<div *ngIf="alert.alertType=='relogin'" class="alert-container alert-confirm">
  <div [ngClass]="'alert-form-item alert-icon alert-'+(alert.color)">
    <i [ngClass]="'fa fa-'+(alert.icon)"></i>
  </div>
  <div class="alert-form-item alert-title2">
    {{alert.msg}}
  </div>

  <div class="alert-form-item alert-field">
    <input type="text" [attr.placeholder]="alert.textId" [(ngModel)]="username">
  </div>

  <div class="alert-form-item alert-field">
    <input type="password" [attr.placeholder]="alert.textPwd" [(ngModel)]="password">
  </div>
  
  <div class="alert-form-item">
    <div class="alert-button" (click)="login()">
      {{alert.textLogin}}
    </div>
  </div>
</div>

<div *ngIf="alert.alertType=='confirmTwo'" class="alert-container alert-confirm">
  <div [ngClass]="'alert-form-item alert-icon alert-'+(alert.color)">
    <i [ngClass]="'fa fa-'+(alert.icon)"></i>
  </div>
  <div class="alert-form-item alert-title2">
    {{alert.msg}}
  </div>
  <div class="alert-form-item" [ngClass]="alert.flex ? 'alert-form-item-flex' : ''">
    <div *ngIf="alert.cancelar !== false" class="cancel alert-button" (click)="cancel('button')">
      Cancelar
    </div>
    <div [ngClass]="alert.colorOne + ' alert-button'" (click)="confirm('buttonOne')">
      {{ alert.buttonOne }}
    </div>
    <div *ngIf="!!alert.buttonTwo" [ngClass]="alert.colorTwo + ' alert-button'" (click)="confirm('buttonTwo')">
      {{ alert.buttonTwo }}
    </div>
  </div>
</div>