import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ModsService } from '../../services/mods.service';
import { SyncService } from '../../services/sync.service';
import { ToolsService } from '../../services/tools.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {

  subscription: Subscription;
  subscription2: Subscription;

  loadingLock: any = false;
  loadingMsg: any = 'Carregando..'
  loading: any = false;
  timeout: any;
  conected: any = false;
  alert: any = {
    text: 'Alerta',
    icon: 'check',
    color: 'success',
    disconnect: false,
    status: false
  }

  constructor(
    private sync: SyncService,
    private mods: ModsService,
    private tools: ToolsService,
    private user: UserService,
    private router: Router,
    private ref: ChangeDetectorRef
  ) {
    this.conected = this.user.getStatus();
    if (!this.conected) { this.router.navigate(['/login'], { fragment: "" }); return }

  }

  ngOnInit(): void {
    this.subscription = this.sync.listenSync.subscribe(data => this.readUpd(data));
    this.subscription2 = this.mods.listenAlert.subscribe(data => this.setAlert(data));
  }

  ngOnDestroy() {
    if (this.conected) {
      this.subscription.unsubscribe();
      this.subscription2.unsubscribe();
    }
  }

  setAlert(data, callback?) {
    // console.log("🚀 ~ data:", data)
    if (typeof data == 'string') {
      data = {
        msg: data,
        icon: 'exclamation',
        color: 'warning',
        alertType: 'alert',
        type: 'alert'
      }
    }

    if (data.alertType == 'confirm') {
    // if (data.alertType == 'confirm' || data.alertType == 'confirmTwo') {

      if (data.confirm) {
        this.alert.status = false;
      }

      if (data.confirm == 'buttonOne') {
        this.alert.status = false;
        this.alert.confirm = this.alert.confirmOne
        this.tools.confirm(this.alert)

        return
      }
      if (data.confirm == 'buttonTwo') {
        this.alert.status = false;
        this.alert.confirm = this.alert.confirmTwo

        this.tools.confirmTwo(this.alert)

        return
      }

      data = {
        msg: data.msg,
        icon: data.icon || 'exclamation',
        color: 'warning',
        type: 'alert',
        alertType: 'confirm',
        confirm: '',
        confirmOne: data.confirmOne,
        cancelar: data.cancelar,
        cancelarCustom: data.cancelar,
        confirmTwo: data.confirmTwo,
        pageType: data.pageType,
        buttonTwoCustom: data.buttonTwoCustom,
        params: data.params
      }
    }

    if (data.alertType == 'relogin') {
      console.log('Relogin');
      
      data = {
        msg: data.msg||'Re-login',
        icon: data.icon || 'lock',
        textId: data.textId||'ID',
        textPwd: data.textPwd||'Password',
        textLogin: data.textLogin||'Login',
        color: 'warning',
        type: 'alert',
        alertType: 'relogin',
        pageType: data.pageType||'modal',
        funcSuccess: data.funcSuccess,
        funcFail: data.funcFail
      }
    }

    if (data.type == 'loading') {
      if (!data.lock && this.loadingLock) {
        return;
      }
      if (data.lock && data.value) {
        this.loadingLock = true;
      } else if (!data.value) {
        this.loadingLock = false;
      }

      this.loading = data.value;
      this.loadingMsg = this.loading && data.msg || 'Carregando';

      setTimeout(() => {
        this.ref.detectChanges();
      }, 100);
      return
    }

    this.alert.status = true;
    this.alert = Object.assign(this.alert, data);

    if (this.alert.disconnect && this.alert.click) {
      this.user.logout();
    }
  }

  readUpd(data) {
    if (data.type == 'loading') {
      this.loading = data.value;
      setTimeout(() => this.ref.detectChanges(), 100);
    }

    if (data.type == 'status' && data.alert) {
      this.mods.updAlert(data.statusText);
    }
  }

}
