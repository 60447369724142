<div class="cards">
	<div  class="card" *ngFor="let item of comp.data">
		<div class="etiq" *ngIf="false && item.off && item.off!=''">
			<div class="text">{{ item.off }}%</div>
		</div>

		<div class="card-container">
			<div class="img-content">
				<img [src]="item.img" onError="this.src='https://static.applayos.com:3000/files/produtos/default.jpg'" />
			</div>
			
			<div class="info-content">
				<div class="ref">Ref: demo</div>
				<div class="descricao"> {{ item.descricao }}</div>
				<div class="valorold">
					<span>De R$ {{ item.de }}</span>
				</div>
				<div class="valor">Por R$ {{ item.valor }}</div>
			</div>
		</div>

		<div class="informacoes-adicionais">
			<div class="obs text-barbosa" *ngIf="item.obs && item.obs!=''">{{item.obs}}</div>
			<div>
				<span *ngIf="item.limiteCPF">Limite por CPF: {{item.limiteCPF}}.</span>
				<span>Válidade até {{item.validadeEnd}}</span>
			</div>
			<div class="obs-estoque text-barbosa">Ou enquanto durarem os estoques</div>
		</div>
	</div>
</div>	
