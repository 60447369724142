import { Component, OnInit, Input } from '@angular/core';
import { ToolsService } from '../../../services/tools.service';


declare var Highcharts: any;
declare var Highstock: any;

@Component({
  selector: 'app-dash-high',
  templateUrl: './dash-high.component.html',
  styleUrls: ['./dash-high.component.css']
})
export class DashHighComponent implements OnInit {

  @Input() public comp: any;

  constructor(private tools: ToolsService) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (!this.comp.highType || this.comp.highType=='highcharts'){
        this.comp.chart = Highcharts.chart('chart-' + this.comp.id, this.comp.data);
      }else{
        this.comp.chart = Highstock.chart('chart-' + this.comp.id, this.comp.data);
      }
    }, 100)
  }

  click(){
    if (this.comp.click && this.comp.click!=''){
      this.tools.runFunc(this.comp.click, this.comp, (this.comp.scope || 'item'));
    }
  }


}
