import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'ApplayOSv3';


  requireLogin:boolean=false;

  constructor(
    private user: UserService,
    private router: Router,
  ) {
    // this.requireLogin = !this.user.getStatus();
    // if(this.requireLogin){
    //   this.router.navigate(['login'],{ fragment:""});
    // }
  }
}
