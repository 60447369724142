<div [ngClass]="!comp.nocard?'card-line-grid':'no-grid'">
  <div class="card-line-title">
    <div>{{comp.name}}</div>
    <div class="qtd-total-title">CAP:{{qtdTotal}}</div>
  </div>
  <div class="card-layout">
    <div *ngFor="let linha of comp.data" class="layout-line">
      <div class="layout-line-title">{{linha.title}}</div>
      <div *ngFor="let box of linha.data" class="layout-box" (click)="click(box)" [ngClass]="'status-'+box.status">
        {{box.number}}
      </div>
    </div>
  </div>
  <div class="card-legenda">
    <div class="info-legenda-qtd">
      <div class="info-legenda">
        <div class="layout-legenda-box status-success"></div>
        <div class="layout-legenda-title">LIVRE</div>
      </div>
      <div class="layout-legenda-title qtd-info">{{qtdLivre}}</div>
    </div>
    <div class="info-legenda-qtd">
      <div class="info-legenda">
        <div class="layout-legenda-box status-primary"></div>
        <div class="layout-legenda-title">RESERVADO</div>
      </div>
      <div class="layout-legenda-title qtd-info">{{qtdReservado}}</div>
    </div>

    <div class="info-legenda-qtd">
      <div class="info-legenda">
        <div class="layout-legenda-box status-danger"></div>
        <div class="layout-legenda-title">OCUPADO</div>
      </div>
      <div class="layout-legenda-title qtd-info">{{qtdOcupado}}</div>
    </div>
  </div>
</div>