<div [ngClass]="!comp.nocard?'card-line-grid':'no-grid'">
  <div class="card-line-title">{{comp.name}}</div>
  <div class="card-line-chart">
    <!-- <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [dataLabels]="chartOptions.dataLabels"
      [stroke]="chartOptions.stroke"
      [title]="chartOptions.title"
      [grid]="chartOptions.grid"
      [xaxis]="chartOptions.xaxis"
    >
    </apx-chart> -->
  </div>
</div>
