import { Component, OnInit, Input } from '@angular/core';
declare var Highcharts: any;

@Component({
  selector: 'app-dash-funnel',
  templateUrl: './dash-funnel.component.html',
  styleUrls: ['./dash-funnel.component.css']
})
export class DashFunnelComponent implements OnInit {

  @Input() public comp: any;

  constructor() { }

  ngOnInit(): void {
    setTimeout(()=>{
      Highcharts.chart('dash-'+this.comp.id, {
          chart: {
              type: 'funnel'
          },
          title: {
              text: this.comp.name
          },
          plotOptions: {
              series: {
                  dataLabels: {
                      enabled: true,
                      format: '<b>{point.name}</b> ({point.y:,.0f})',
                      softConnector: true
                  },
                  center: ['40%', '50%'],
                  neckWidth: '30%',
                  neckHeight: '25%',
                  width: '80%'
              }
          },
          credits:{
            enabled:false
          },
          legend: {
              enabled: false
          },
          series: this.comp.data,

          responsive: {
              rules: [{
                  condition: {
                      maxWidth: 500
                  },
                  chartOptions: {
                      plotOptions: {
                          series: {
                              dataLabels: {
                                  inside: true
                              },
                              center: ['50%', '50%'],
                              width: '100%'
                          }
                      }
                  }
              }]
          }
      });
    },100)
  }

}
